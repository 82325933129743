// Actions
const CHANGE_LANGUAGE = 'changeLanguage'
const LOAD_METADATA = 'loadMetaData'
const CHANGE_SIDEBAR_STATE = 'changeSidebarState'
const CLOSE_SIDEBAR_STATE = 'closeSidebarState'
const CHANGE_SIDEBAR_MOBILE_STATE = 'changeSidebarMobileState'
const CLOSE_SIDEBAR_MOBILE_STATE = 'closeSidebarMobileState'
const CHANGE_CATEGORIES_PINS_MOBILE_STATE = 'changeCategoriesPinsMobileState'
const CLOSE_CATEGORIES_PINS_MOBILE_STATE = 'closeCategoriesPinsMobileState'
const CHANGE_FILTER_STATE = 'changeFilterState'
const CHANGE_PAGE_LOADING_STATE = 'changePageLoadingState'
const TOGGLE_SIDEBAR = 'toggleSidebar'
const TOGGLE_SIDEBAR_MOBILE = 'toggleSidebarMobile'
const TOGGLE_CATEGORIES_PINS_MOBILE = 'toggleCategoriesPinsMobile'
const TOGGLE_FILTER = 'toggleFilter'
const SHOW_FILTER = 'showFilter'
const TOGGLE_INFO = 'toggleInfo'
const CLOSE_FILTER = 'closeFilter'
const TOGGLE_AVAILABILITY_MENU = 'toggleAvailabilityMenu'
const TOGGLE_CHOOSE_VIEW_MENU = 'toggleChooseViewMenu'
const CLOSE_CHOOSE_VIEW_MENU = 'closeChooseViewMenu'
const CHANGE_AVAILABILITY_MENU = 'changeAvailabilityMenu'
const TOGGLE_COLLAPSED_MENU = 'toggleCollapsedMenu'
const CHANGE_COLLAPSED_MENU = 'changeCollapsedMenu'
const CHANGE_DOCUMENT_READY_STATE = 'changeDocumentReadyState'
const TOGGLE_SHOW_LEASED_SPACES = 'toggleShowLeasedSpaces'
const TOGGLE_INTERACTIVE_MOUSE_ENABLED = 'toggleInteractiveMouseEnabled'

// Mutations
const SET_LANGUAGE = 'setLanguage'
const SET_METADATA = 'setMetaData'
const SET_SIDEBAR_STATE = 'setSidebarState'
const SET_SIDEBAR_MOBILE_STATE = 'setSidebarMobileState'
const SET_CATEGORIES_PINS_MOBILE_STATE = 'setCategoriesPinsMobileState'
const SET_FILTER_STATE = 'setFilterState'
const SET_INFO_STATE = 'setInfoState'
const SET_PAGE_LOADING_STATE = 'setPageLoadingState'
const SET_AVAILABILITY_MENU = 'setAvailabilityMenu'
const SET_CHOOSE_VIEW_MENU = 'setChooseViewMenu'
const SET_COLLAPSED_MENU = 'setCollapsedMenu'
const SET_DOCUMENT_READY_STATE = 'setDocumentReadyState'
const SET_ENGINE_3D_PLUGIN_LOADING = 'setEngine3DPluginLoading'
const SET_SHOW_LEASED_SPACES = 'setShowLeasedSpaces'
const SET_INTERACTIVE_MOUSE_ENABLED = 'setInteractiveMouseEnabled'

// Namespace
const NAMESPACE = 'base'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    CHANGE_LANGUAGE,
    TOGGLE_SIDEBAR,
    TOGGLE_SIDEBAR_MOBILE,
    TOGGLE_CATEGORIES_PINS_MOBILE,
    TOGGLE_FILTER,
    SHOW_FILTER,
    TOGGLE_INFO,
    CLOSE_FILTER,
    CHANGE_SIDEBAR_STATE,
    CLOSE_SIDEBAR_STATE,
    CHANGE_SIDEBAR_MOBILE_STATE,
    CLOSE_SIDEBAR_MOBILE_STATE,
    CHANGE_CATEGORIES_PINS_MOBILE_STATE,
    CLOSE_CATEGORIES_PINS_MOBILE_STATE,
    CHANGE_FILTER_STATE,
    LOAD_METADATA,
    CHANGE_PAGE_LOADING_STATE,
    TOGGLE_AVAILABILITY_MENU,
    TOGGLE_CHOOSE_VIEW_MENU,
    CLOSE_CHOOSE_VIEW_MENU,
    CHANGE_AVAILABILITY_MENU,
    TOGGLE_COLLAPSED_MENU,
    CHANGE_COLLAPSED_MENU,
    CHANGE_DOCUMENT_READY_STATE,
    TOGGLE_SHOW_LEASED_SPACES,
    TOGGLE_INTERACTIVE_MOUSE_ENABLED
  },
  mutation: {
    SET_LANGUAGE,
    SET_SIDEBAR_STATE,
    SET_SIDEBAR_MOBILE_STATE,
    SET_CATEGORIES_PINS_MOBILE_STATE,
    SET_FILTER_STATE,
    SET_INFO_STATE,
    SET_METADATA,
    SET_PAGE_LOADING_STATE,
    SET_AVAILABILITY_MENU,
    SET_CHOOSE_VIEW_MENU,
    SET_COLLAPSED_MENU,
    SET_DOCUMENT_READY_STATE,
    SET_ENGINE_3D_PLUGIN_LOADING,
    SET_SHOW_LEASED_SPACES,
    SET_INTERACTIVE_MOUSE_ENABLED
  },
  namespace: NAMESPACE,
  withNamespace
}
